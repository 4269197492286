@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}
/* 
html.dark {
  background-color: rgb(38, 38, 38);
} */

/******** NOTE EDITOR *******/

.dark .md-editor .ProseMirror {
  color: #fff;
}

.md-editor .ProseMirror {
  background-color: transparent;
}

.md-editor .ProseMirror p > a {
  text-decoration: underline;
}

.text-mdx a {
  text-decoration: underline;
}

.max-h-screendvh {
  max-height: 100vh;
}

.h-screendvh {
  height: 100vh;
}

/* if mobile use 100dvh */
@media (max-width: 768px) {
  .h-screendvh {
    height: 100dvh;
  }
  .max-h-screendvh {
    max-height: 100dvh;
  }
}

/* .text-mdx {
  white-space: pre-wrap;
} */

.text-mdx ol {
  margin-left: 1rem;
}

.text-mdx p {
  margin-bottom: 0.6rem;
}

.text-mdx ul {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.text-mdx h1 {
  margin-bottom: 1rem;
  font-size: large;
}

.text-mdx h2 {
  margin-bottom: 1rem;
  font-size: medium;
}

.text-mdx h3 {
  margin-bottom: 1rem;
  font-size: small;
}

.md-editor > div > .ProseMirror h1 {
  font-size: 32px;
  font-weight: 700;
  /* line-height: 1.2; */
}

.md-editor > div > .ProseMirror [data-level="1"] {
  font-size: 32px !important;
  font-weight: 700;
  /* line-height: 1.2; */
}

.md-editor > div > .ProseMirror h2 {
  font-size: 26px;
  /* line-height: 1.3; */
  font-weight: 600;
}

.md-editor > div > .ProseMirror [data-level="2"] {
  font-size: 26px !important;
  font-weight: 700;
  /* line-height: 1.2; */
}

.md-editor > div > .ProseMirror h3 {
  font-size: 20px;
  /* line-height: 1.4; */
  font-weight: 600;
}

.md-editor > div > .ProseMirror [data-level="3"] {
  font-size: 20px !important;
  font-weight: 700;
  /* line-height: 1.2; */
}

.md-editor > div > .ProseMirror h4 {
  font-size: 16px;
  /* line-height: 1.5; */
  font-weight: 600;
}

.md-editor > div > .ProseMirror p {
  font-size: 16px;
  /* line-height: 1.5; */
  font-weight: 400;
}

body.dark .md-editor > div > .ProseMirror p {
  color: #fff;
}
body.dark .md-editor > div > .ProseMirror h1 {
  color: #fff;
}
body.dark .md-editor > div > .ProseMirror h2 {
  color: #fff;
}
body.dark .md-editor > div > .ProseMirror h3 {
  color: #fff;
}
body.dark .md-editor > div > .ProseMirror h4 {
  color: #fff;
}
body.dark .md-editor > div [data-content-type="bulletListItem"]:before {
  color: #fff;
}

body.dark .md-editor > div [data-content-type="numberedListItem"]:before {
  color: #fff;
}

/* #tippy-2 {
  overflow-x: auto;
}

#tippy-3 {
  overflow-x: auto;
}

[data-tippy-id-root]:has(> div > div > div .formatting-toolbar) {
  overflow-x: auto !important;
}

.tippy-content > div > .mantine-Menu-dropdown {
  width: 300px !important;
}
 */

.codeEditor {
  counter-reset: line;
  border: 1px solid #ced4da;
}

.codeEditor #codeArea {
  outline: none;
  padding-left: 40px !important;
}

.codeEditor pre {
  padding-left: 40px !important;
}

.codeEditor .editorLineNumber {
  position: absolute;
  left: 0px;
  color: #cccccc;
  text-align: right;
  width: 20px;
  font-weight: 100;
}

/* if mobile max-width is 500px and overflow is auto */
@media screen and (max-width: 768px) {
  .mantine-Toolbar-root {
    max-width: 500px;
    overflow: auto;
  }
}

.img-visual-preview::before {
  content: "";
  width: 100%;
  height: calc(100% + 10px);
  background-color: #ccc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.img-visual-preview::after {
  content: "\2639"" " attr(alt);

  font-size: 9px;
  text-align: center;
  color: rgb(100, 100, 100);

  display: block;
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 0;
  width: 100%;
  height: 100%;
}
